.container {
  margin-top: 16px;
  width: 100%;
}

.form_error,
.form_field_error {
  color: var(--color-error);
  margin-top: 8px;
}

.form_error {
  font-size: 18px;
  line-height: 20px;
}

.form_field_error {
  font-size: 14px;
  line-height: 18px;
}

.form_field {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.form_field.form_checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
}

.form_field label {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: var(--color-primary);
}

.form_field_input,
.form_field_input-disabled {
  width: 100%;
  position: relative;
  padding: 8px 0;
  border-width: 2px;
  border-color: var(--color-primary);
  border-style: solid;
  border-radius: 10px;
}

.form_field_input-disabled {
  background-color: var(--color-disabled);
}

.form_field_input input,
.form_field_input textarea,
.form_field_input-disabled input,
.form_field_input-disabled textarea {
  padding: 0 16px;
  width: 100%;
  border: none;
  font-family: var(--font-alt);
  background-color: transparent;
  outline: none;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
}

.form_field_input input,
.form_field_input textarea {
  color: var(--color-primary);
}

.form_field_checkbox {
  width: fit-content;
  position: relative;
  padding: 8px 0;
}

.form_field_input-disabled input,
.form_field_input-disabled textarea {
  color: var(--color-secondary);
}

.form_field_input input:focus,
.form_field_input textarea:focus,
.form_field_input-disabled input:focus,
.form_field_input-disabled textarea:focus {
  outline: none;
}

.form_field_input textarea {
  resize: vertical;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

.form_field_input-icon_end {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
  justify-content: center;
}

.form_field_input-icon_end svg {
  padding: 4px;
  user-select: none;
  cursor: pointer;
  color: var(--color-primary);
  font-weight: 900;
  font-size: 30px;
  width: 34px;
  height: 34px;
}

.form_field_input-icon_end svg:hover {
  color: var(--color-primary-hover);
}

.language-selection {
  width: fit-content;
  margin: 16px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.language-selection-button_active,
.language-selection-button_inactive {
  padding: 8px;
  font-weight: 500;
  font-family: var(--font-base);
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-selection-button_active.has-error,
.language-selection-button_inactive.has-error {
  border: 2px solid var(--color-error);
}

.language-selection-button_inactive {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.language-selection-button_inactive:hover {
  background-color: var(--color-primary-hover);
  color: var(--color-secondary);
}

.language-selection-button_active {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.media-add-button,
.media-add-button-disabled {
  width: 100%;
  position: relative;
  border-width: 2px;
  border-color: var(--color-primary);
  border-style: solid;
  border-radius: 10px;
  padding: 8px 16px;
  font-family: var(--font-alt);
  background-color: transparent;
  color: var(--color-primary);
  outline: none;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
  cursor: pointer;
}

.media-add-button-disabled {
  background-color: var(--color-disabled);
}

@media screen and (max-width: 1150px) {
  .form_error {
    font-size: 16px;
    line-height: 18px;
  }

  .form_field_error {
    font-size: 12px;
    line-height: 12px;
  }

  .form_field_input input,
  .form_field_input textarea {
    padding: 0 8px;
  }
}
