.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
}

.spinner svg {
  width: min(50vh, 50vw);
  height: min(50vh, 50vw);
  flex: 1;
  fill: var(--color-primary);
  color: gray;
}
