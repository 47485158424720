.overlay {
  position: fixed;
  inset: 0;
  overflow: hidden;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: rgba(107, 114, 128, 0.5);
  z-index: 50;
}

.dialog {
  width: 90%;
  max-height: 90vh;
  box-sizing: border-box;
  padding: 0;
  background-color: var(--color-secondary);
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.dialog-header {
  justify-self: flex-start;
  text-align: center;
  width: 100%;
  padding: 8px;
  font-size: 30px;
  line-height: 36px;
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.dialog-content {
  margin-top: 8px;
  padding: 8px;
  flex-grow: 1;
  font-size: 20px;
  line-height: 22px;
  overflow-x: hidden;
  overflow-y: auto;
  color: var(--color-primary);
}

.dialog-actions {
  justify-self: flex-end;
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid var(--color-primary);
  width: 100%;
  padding: 8px;
}

.dialog-actions button {
  min-width: 30%;
  border-radius: 10px;
  padding: 8px;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  outline: 0;
}

.dialog-actions_primary {
  border: 0;
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.dialog-actions_primary:hover {
  background-color: var(--color-primary-hover);
}

.dialog-actions_secondary {
  border: 1px solid var(--color-primary);
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.dialog-actions_secondary:hover {
  background-color: var(--color-secondary-hover);
}

.dialog-actions_error {
  border: 0;
  background-color: var(--color-error);
  color: var(--color-secondary);
}

.dialog-actions_error:hover {
  background-color: var(--color-error-hover);
}

.gallery-grid {
  width: 100%;
  padding: 32px;
  margin: 0 auto;
  justify-content: center;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 1fr;
  grid-gap: 36px;
}

.gallery-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--color-primary);
  background-color: var(--color-secondary);
}

.gallery-image {
  display: flex;
  background-color: var(--color-primary);
  width: 300px;
  height: 300px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.gallery-image::after {
  content: "Επιλογή";
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(192, 192, 192, 0.8);
  width: 100%;
  height: 100%;
  transform: scaleY(0);
  transition: transform 0.3s ease-in-out;
}

.gallery-image:hover::after {
  transform: scaleY(1);
}

.gallery-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media screen and (min-width: 2000px) {
  .overlay {
    padding: 60px;
  }

  .dialog {
    width: 75%;
  }

  .dialog-header {
    font-size: 34px;
    line-height: 40px;
  }

  .dialog-content {
    font-size: 18px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1150px) {
  .overlay {
    padding: 20px;
  }

  .dialog {
    width: 80%;
  }

  .dialog-header {
    font-size: 24px;
    line-height: 26px;
  }

  .dialog-content {
    font-size: 18px;
    line-height: 20px;
  }
}

@media screen and (max-width: 650px) {
  .overlay {
    padding: 12px;
  }

  .dialog {
    width: 95%;
  }

  .dialog-header {
    font-size: 22px;
    line-height: 22px;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 100%));
    grid-auto-rows: 1fr;
    grid-gap: 36px;
    justify-items: center;
    margin: 0 auto;
    padding: 0;
  }

  .gallery-image {
    width: 100%;
    height: 200px;
  }
}
