.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner svg {
  max-width: min(200px, 90%);
  max-height: min(200px, 90%);
  flex: 1;
  fill: var(--color-primary);
  color: gray;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

.year-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.year-selector label {
  font-size: 20px;
}

.year-selector select {
  padding: 12px 8px;
  background-color: var(--color-primary);
  color: var(--color-main);
  font-size: 20px;
}
