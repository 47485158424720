.videos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 1fr;
  grid-gap: 36px;
  justify-items: center;
  margin: 0 auto;
  padding: 0;
}

@media screen and (max-width: 650px) {
  .videos-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 100%));
    grid-auto-rows: 1fr;
    grid-gap: 36px;
    justify-items: center;
    margin: 0 auto;
    padding: 0;
  }
}

.reorder-btn-container {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.reorder-btn {
  margin-top: 8px;
  min-width: 30%;
  border-radius: 10px;
  padding: 8px;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  outline: 0;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border-width: 0;
}

.reorder-btn:hover {
  background-color: var(--color-primary-hover);
}

.reorder-btn:disabled {
  cursor: not-allowed;
  background-color: var(--color-disabled);
  color: var(--color-secondary);
}
