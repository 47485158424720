.press-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: 1fr;
  grid-gap: 36px;
  justify-items: center;
  margin: 0 auto;
  padding: 0;
}

@media screen and (max-width: 650px) {
  .press-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 100%));
    grid-auto-rows: 1fr;
    grid-gap: 36px;
    justify-items: center;
    margin: 0 auto;
    padding: 0;
  }
}
