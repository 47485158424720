.concerts-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-items: center;
  margin: 0 auto;
  padding: 0;
}

@media screen and (max-width: 650px) {
  .concerts-list {
    gap: 1rem;
  }
}
