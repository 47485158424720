.press-navigation {
  width: fit-content;
  margin: 16px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.press-navigation-link_active,
.press-navigation-link_inactive,
.press-navigation-link_disabled {
  padding: 8px;
  font-weight: 500;
  font-family: var(--font-base);
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.press-navigation-link_inactive {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.press-navigation-link_inactive:hover {
  background-color: var(--color-primary-hover);
  color: var(--color-secondary);
}

.press-navigation-link_active {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.press-navigation-link_disabled,
.press-navigation-link_disabled:hover {
  cursor: not-allowed;
  background-color: var(--color-disabled);
  color: var(--color-secondary);
}

.press-navigation-link_active.press-navigation-link_disabled {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}
