.form_field {
  margin-top: 16px;
}

.form_field label,
.form_field h2 {
  font-family: var(--font-base);
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: var(--color-primary);
  text-align: center;
}

.form_text {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.04em;
  color: var(--color-primary);
  margin-top: 8px;
}

.form_field_status {
  color: var(--color-primary);
  margin-top: 2px;
  font-size: 12px;
  line-height: 12px;
}

.form_field_error {
  font-size: 14px;
  line-height: 18px;
}

.form_hidden {
  display: none;
}

.form_images {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.form_field_image {
  width: 250px;
  height: 250px;
  margin: 8px;
  cursor: pointer;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.form_field_image:hover {
  background-color: var(--color-primary-hover);
}

.form_field_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.form_field_image p,
.form_field_image svg {
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  color: var(--color-secondary);
}

.form_field_image svg {
  font-size: 40px;
  line-height: 40px;
}

.form_field_image-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;
  font-size: 32px;
  padding: 8px;
  color: var(--color-primary-faded);
  background-color: var(--color-secondary-faded);
  border-radius: 100%;
}

.form_field_image:hover .form_field_image-icon {
  color: var(--color-primary);
  background-color: var(--color-secondary);
}

.form_btn_container {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.form_submit_btn {
  margin-top: 8px;
  min-width: 30%;
  border-radius: 10px;
  padding: 8px;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  outline: 0;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border-width: 0;
}

.form_submit_btn:hover {
  background-color: var(--color-primary-hover);
}

.form_submit_btn:disabled {
  cursor: not-allowed;
  background-color: var(--color-disabled);
  color: var(--color-secondary);
}

.form_error {
  color: var(--color-error);
  margin: 8px 0;
  font-size: 18px;
  line-height: 20px;
}

@media screen and (max-width: 1150px) {
  .form_text {
    font-size: 14px;
    line-height: 16px;
  }

  .form_field_image {
    width: 200px;
    height: 200px;
  }

  .form_field_image p {
    font-size: 16px;
    line-height: 16px;
  }

  .form_field_image-icon {
    font-size: 26px;
  }

  .form_field_status {
    font-size: 12px;
    line-height: 12px;
  }

  .form_error {
    font-size: 16px;
    line-height: 18px;
  }
}

@media screen and (max-width: 650px) {
  .form_text {
    font-size: 14px;
    line-height: 14px;
  }

  .form_field_image {
    width: 150px;
    max-width: 90%;
    height: 150px;
  }

  .form_field_image-icon {
    font-size: 20px;
  }

  .form_field_image p {
    font-size: 14px;
    line-height: 14px;
  }

  .form_field_status {
    font-size: 10px;
    line-height: 10px;
  }
}
