.concert-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  color: var(--color-primary);
  background-color: var(--color-secondary);
}

.concert-info {
  text-align: left;
  word-wrap: break-word;
  padding: 8px;
  font-family: var(--font-base);
  flex-grow: 1;
}

.concert-info p:first-child {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: var(--color-primary);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.concert-info p:nth-child(2) {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  line-height: 24px;
  color: var(--color-disabled);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.concert-actions {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  padding: 8px;
  justify-content: space-around;
  justify-self: flex-end;
}

.concert-actions svg {
  padding: 4px;
  user-select: none;
  cursor: pointer;
  color: var(--color-primary);
  font-weight: 900;
  font-size: 30px;
  width: 34px;
  height: 34px;
}

.concert-actions svg:hover {
  color: var(--color-primary-hover);
}

.concert-actions-small {
  display: none;
}

.concert-actions-small button {
  min-width: 30%;
  border-radius: 10px;
  padding: 8px;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  outline: 0;
  word-break: break-all;
  overflow-wrap: break-word;
}

.concert-actions-small button:disabled {
  cursor: not-allowed;
  background-color: var(--color-disabled);
  color: var(--color-secondary);
}

.btn_primary {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border-width: 0;
}

.btn_primary:hover {
  background-color: var(--color-primary-hover);
}

.btn_error {
  background-color: var(--color-error);
  color: var(--color-secondary);
}

.btn_error:hover {
  background-color: var(--color-error-hover);
}

@media screen and (max-width: 1150px) {
  .concert-info {
    font-size: 22px;
    line-height: 26px;
  }

  .concert-actions svg {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 650px) {
  .concert-container {
    flex-direction: column;
  }

  .concert-actions {
    flex-direction: row;
  }
}
