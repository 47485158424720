.form-container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-family: var(--font-base);
}

.form {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 16px;
  background-color: var(--color-secondary);
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}

.form_title {
  font-size: 30px;
  line-height: 36px;
  color: var(--color-primary);
}

.form_error,
.form_field_error {
  color: var(--color-error);
  margin-top: 8px;
}

.form_error {
  font-size: 18px;
  line-height: 20px;
}

.form_field_error {
  font-size: 14px;
  line-height: 18px;
}

.form_field {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.form_field label {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
  color: var(--color-primary);
}

.form_field_input,
.form_field_input_password {
  width: 100%;
  padding: 8px 0;
  border-width: 0 0 2px 0;
  border-color: var(--color-primary);
  border-style: solid;
}

.form_field_input_password {
  display: flex;
}

.form_field_input input,
.form_field_input_password input {
  padding: 0 16px;
  border: none;
  font-family: var(--font-alt);
  color: var(--color-primary);
  background-color: transparent;
  outline: none;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.04em;
}

.form_field_input input:focus,
.form_field_input_password input:focus {
  outline: none;
}

.form_field_input input {
  width: 100%;
}

.form_field_input_password input {
  flex-grow: 1;
}

.form_field_input_password_icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 8px;
  padding: 0 8px;
  background-color: transparent;
}

.form_field_input_password_icon svg {
  flex: 1 1 0%;
  width: 25px;
  height: 25px;
  margin: 0 auto;
  color: var(--color-primary);
}

.form_field_input_password_icon svg:hover {
  color: var(--color-primary-hover);
}

.form_field_input_otp {
  margin-top: 8px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.form_field_input_otp input {
  width: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid var(--color-primary);
  border-radius: 10px;
  font-family: var(--font-alt);
  color: var(--color-primary);
  outline: none;
  font-size: 22px;
  line-height: 28px;
}

/* Chrome, Safari, Edge, Opera */
.form_field_input_otp input::-webkit-outer-spin-button,
.form_field_input_otp input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
.form_field_input_otp input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.form_submit_btn,
.form_cancel_btn {
  margin-top: 8px;
  width: 100%;
  border-radius: 10px;
  padding: 8px;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  border-width: 0;
  outline: 0;
}

.form_submit_btn {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.form_submit_btn:hover {
  background-color: var(--color-primary-hover);
}

.form_cancel_btn {
  background-color: var(--color-secondary-hover);
  color: var(--color-primary);
}

.form_cancel_btn:hover {
  background-color: var(--color-secondary-faded);
}

.form_submit_btn:disabled,
.form_cancel_btn:disabled {
  cursor: not-allowed;
  background-color: var(--color-disabled);
  color: var(--color-secondary);
}

.form_redirect_links {
  margin-top: 8px;
  color: var(--color-primary);
  user-select: none;
}

.form_redirect_links:hover {
  color: var(--color-primary-hover);
}

.form_recaptcha_container {
  margin-top: 16px;
}

.form_recaptcha_container a {
  color: blue;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

@media screen and (max-width: 1150px) {
  .form-container {
    flex-grow: 0;
  }

  .form {
    border-radius: 10px;
  }

  .form_error {
    font-size: 16px;
    line-height: 18px;
  }

  .form_field_error {
    font-size: 12px;
    line-height: 12px;
  }

  .form_field_input input,
  .form_field_input_password input {
    padding: 0 8px;
  }

  .form_field_input_password_icon {
    padding: 0 4px;
  }
}
