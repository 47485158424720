@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap");

:root {
  --font-base: "Open Sans", sans-serif;
  --font-alt: "Open Sans", sans-serif;

  --color-primary: rgba(0, 0, 0, 1);
  --color-primary-hover: rgba(32, 32, 32, 1);
  --color-primary-faded: rgba(32, 32, 32, 0.7);
  --color-secondary: rgba(200, 200, 200, 1);
  --color-secondary-hover: rgba(255, 255, 255, 1);
  --color-secondary-faded: rgba(255, 255, 255, 0.7);
  --color-main: rgba(236, 102, 84, 1);
  --color-main-hover: rgba(200, 70, 50, 1);
  --color-theme-gray: #888888;
  --color-light-gray: #ececef;
  --color-error: rgba(255, 0, 0, 1);
  --color-error-hover: rgba(255, 96, 0, 1);
  --color-error-faded: rgba(255, 0, 0, 0.7);
  --color-disabled: rgba(100, 100, 100, 1);
  --color-disabled-faded: rgba(100, 100, 100, 0.5);
  --color-unavailable: rgba(100, 100, 100, 0.6);
  --color-warning: rgba(255, 255, 0, 1);
  --color-warning-hover: rgba(255, 255, 0, 0.7);
  --color-black: rgba(0, 0, 0);
  --color-white: rgba(255, 255, 255);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.header-base {
  font-family: var(--font-base);
  font-weight: 900;
  letter-spacing: 0.04em;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 40px;
  font-size: 30px;
}

.text-base {
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 29.9px;
  font-size: 23px;
}

.text-alt {
  font-family: var(--font-alt);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
}

.text-error {
  font-family: var(--font-base);
  font-weight: 700;
  font-style: italic;
  color: var(--color-error);
  font-feature-settings: "tnum" on, "lnum" on;
  line-height: 20px;
  font-size: 20px;
}

.section-padding {
  padding: 3rem 4rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-primary,
.btn-secondary,
.btn-main,
.btn-error {
  cursor: pointer;
  border: 0;
  word-wrap: break-word;
}

.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.btn-primary:hover {
  background-color: var(--color-primary-hover);
}

.btn-secondary {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

.btn-secondary:hover {
  background-color: var(--color-secondary-hover);
}

.btn-main {
  background-color: var(--color-main);
  color: var(--color-secondary);
}

.btn-main:hover {
  background-color: var(--color-main-hover);
}

.btn-error {
  background-color: var(--color-error);
  color: var(--color-secondary);
}

.btn-error:hover {
  background-color: var(--color-error-hover);
}

.btn-primary:disabled,
.btn-secondary:disabled,
.btn-main:disabled,
.btn-error:disabled {
  cursor: not-allowed;
  background-color: var(--color-disabled);
  color: var(--color-secondary);
}

.grecaptcha-badge {
  visibility: hidden;
}

.g-recaptcha a {
  margin: 0 0.3em;
  display: inline;
  color: blue;
}

/* Pulse animation class */
.pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  -moz-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  -o-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@-moz-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-o-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@-ms-keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Spin animation class */
.spin {
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 2000px) {
  .header-base {
    font-size: 44px;
    line-height: 60px;
  }

  .text-base {
    font-size: 37px;
    line-height: 67px;
  }

  .text-alt {
    font-size: 30px;
    line-height: 50px;
  }

  .text-error {
    font-size: 25px;
    line-height: 25px;
  }
}

@media screen and (max-width: 850px) {
  .section-padding {
    padding: 3rem 2rem;
  }
}

@media screen and (max-width: 650px) {
  .section-padding {
    padding: 3rem 2rem;
  }

  .header-base {
    font-size: 26px;
  }

  .text-base {
    font-size: 21px;
  }

  .text-alt {
    font-size: 14px;
  }

  .text-error {
    font-size: 14px;
    line-height: 14px;
  }
}

@media screen and (max-width: 450px) {
  .header-base {
    font-size: 23px;
  }

  .text-base {
    font-size: 19px;
  }

  .text-alt {
    font-size: 12px;
  }

  .text-error {
    font-size: 12px;
    line-height: 12px;
  }
}
