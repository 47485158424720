.files-title {
  font-family: var(--font-base);
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: var(--color-primary);
  text-align: center;
}

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.files-error {
  color: var(--color-error);
  margin: 8px 0;
  font-size: 18px;
  line-height: 20px;
}

.file-container {
  width: 250px;
  height: 250px;
  margin: 8px;
  cursor: pointer;
  background-color: var(--color-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.file-container div,
.file-container canvas {
  margin: 0 auto;
  width: 250px !important;
  height: 250px !important;
}

.file-container::after {
  content: "Διαγραφή";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
  -moz-transform-origin: bottom;
  -o-transform-origin: bottom;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.file-container:hover::after {
  -moz-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
}

@media screen and (max-width: 1150px) {
  .file-container {
    width: 200px;
    height: 200px;
  }

  .file-container div,
  .file-container canvas {
    width: 200px !important;
    height: 200px !important;
  }

  .files-error {
    font-size: 16px;
    line-height: 18px;
  }
}

@media screen and (max-width: 650px) {
  .file-container {
    width: 150px;
    max-width: 90%;
    height: 150px;
  }

  .file-container div,
  .file-container canvas {
    width: 150px !important;
    height: 150px !important;
  }
}
