.press-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--color-primary);
  background-color: var(--color-secondary);
}

.press-info {
  text-align: left;
  word-wrap: break-word;
  padding: 8px;
  font-family: var(--font-base);
  font-weight: regular;
  font-size: 16px;
  flex-grow: 1;
}

.press-info p:first-child {
  text-align: left;
  word-wrap: break-word;
  padding: 8px;
  font-family: var(--font-base);
  font-weight: regular;
  font-size: 16px;
  flex-grow: 1;
  letter-spacing: 0.04em;
  color: var(--color-primary);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.press-info p:nth-child(2) {
  font-size: 20px;
  font-weight: 500;
  font-style: italic;
  line-height: 24px;
  color: var(--color-disabled);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.press-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  padding: 8px;
  justify-content: space-around;
  justify-self: flex-end;
}

.press-actions svg {
  padding: 4px;
  user-select: none;
  cursor: pointer;
  color: var(--color-primary);
  font-weight: 900;
  font-size: 30px;
  width: 34px;
  height: 34px;
}

.press-actions svg:hover {
  color: var(--color-primary-hover);
}

.btn_primary {
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border-width: 0;
}

.btn_primary:hover {
  background-color: var(--color-primary-hover);
}

.btn_error {
  background-color: var(--color-error);
  color: var(--color-secondary);
}

.btn_error:hover {
  background-color: var(--color-error-hover);
}

@media screen and (max-width: 1150px) {
  .press-info {
    font-size: 22px;
    line-height: 26px;
  }

  .press-actions svg {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 650px) {
  .press-image {
    width: 100%;
    height: 200px;
  }
}
